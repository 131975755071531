<template>
  <div class="account-box">
    <!-- <div class="toggle">
      <a @click="isMsgLogin=false" href="javascript:;" v-if="isMsgLogin">
        <i class="iconfont icon-user"></i> 使用账号登录
      </a>
      <a @click="isMsgLogin=true" href="javascript:;" v-else>
        <i class="iconfont icon-msg"></i> 使用短信登录
      </a>
    </div> -->
    <Form
      ref="formCom"
      class="form"
      :validation-schema="schema"
      v-slot="{ errors }"
      autocomplete="off"
    >
      <template v-if="!isMsgLogin">
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-user"></i>
            <Field
              :class="{ error: errors.account }"
              v-model="form.account"
              name="account"
              type="text"
              placeholder="请输入用户名"
            />
          </div>
          <div class="error" v-if="errors.account">
            <i class="iconfont icon-warning" />
            {{ errors.account }}
          </div>
        </div>
        <div class="form-item">
          <div class="input">
            <i class="iconfont icon-lock"></i>
            <Field
              :class="{ error: errors.password }"
              v-model="form.password"
              name="password"
              type="password"
              placeholder="请输入密码"
            />
          </div>
          <div class="error" v-if="errors.password">
            <i class="iconfont icon-warning" />
            {{ errors.password }}
          </div>
        </div>
        <!-- <div class="form-item code-item">
          <div class="input">
            <i class="iconfont icon-code"></i>
            <Field
              :class="{ error: errors.code }"
              v-model="form.code"
              name="code"
              type="text"
              placeholder="请输入验证码"
            />
          </div>
          <div class="error" v-if="errors.code">
            <i class="iconfont icon-warning" />
            {{ errors.code }}
          </div>
          <div class="imgs" @click="getCaptcha()">
            <img :src="captchatImg" />
          </div>
        </div> -->
      </template>
      <template v-else>
        <div class="qrcode-box">
          <!-- <img src="@/assets/images/qrcode.jpg" alt="" /> -->
          <template v-if="activeName == '1'">
            <urlToScan :url="config.userRegisterUrl"></urlToScan>
          </template>
          <template v-else-if="activeName == '2'">
            <urlToScan :url="config.merchantUrl"></urlToScan>
          </template>
          <template v-else>
            <img :src="config.assessUrl" style="width: 200px;height: 200px;" />
          </template>
          <p>打开 <a href="javascript:;">微信</a> 扫码注册</p>
        </div>
        <template v-if="false">
          <div class="form-item">
            <div class="input">
              <i class="iconfont icon-user"></i>
              <Field
                :class="{ error: errors.mobile }"
                v-model="form.mobile"
                name="mobile"
                type="text"
                placeholder="请输入手机号"
              />
            </div>
            <div class="error" v-if="errors.mobile">
              <i class="iconfont icon-warning" />
              {{ errors.mobile }}
            </div>
          </div>
          <div class="form-item">
            <div class="input">
              <i class="iconfont icon-code"></i>
              <Field
                :class="{ error: errors.code }"
                v-model="form.code"
                name="code"
                type="text"
                placeholder="请输入验证码"
              />
              <span @click="send()" class="code">
                {{ time === 0 ? "发送验证码" : `${time}秒后发送` }}
              </span>
            </div>
            <div class="error" v-if="errors.code">
              <i class="iconfont icon-warning" />
              {{ errors.code }}
            </div>
          </div>
          <div class="form-item">
            <div class="input">
              <i class="iconfont icon-lock"></i>
              <Field
                :class="{ error: errors.password }"
                v-model="form.password"
                name="password"
                type="password"
                placeholder="请输入密码"
              />
            </div>
            <div class="error" v-if="errors.password">
              <i class="iconfont icon-warning" />
              {{ errors.password }}
            </div>
          </div>
        </template>
      </template>
      <div class="form-item">
        <!-- <div class="agree">
          <Field as="XtxCheckbox" name="isAgree" v-model="form.isAgree" />
          <span>我已同意</span>
          <a href="javascript:;">《隐私条款》</a>
          <span>和</span>
          <a href="javascript:;">《服务条款》</a>
        </div> -->
        <div class="error" v-if="errors.isAgree">
          <i class="iconfont icon-warning" />
          {{ errors.isAgree }}
        </div>
      </div>
      <a v-if="!isMsgLogin" @click="login()" href="javascript:;" class="btn"
        >登录</a
      >
      <!-- <a v-else @click="register()" href="javascript:;" class="btn">注册</a> -->
    </Form>
    <div class="action">
      <!-- <a :href="qqUrl">
        <img
          src="https://qzonestyle.gtimg.cn/qzone/vas/opensns/res/img/Connect_logo_7.png"
          alt=""
        />
      </a> -->
      <div class="url">
        <!-- <a href="javascript:;">忘记密码</a> -->
        <template v-if="!isMsgLogin">
          <a href="javascript:;" @click="changeStatus(true)">注册</a>
        </template>
        <template class="url" v-else>
          <a href="javascript:;" @click="changeStatus(false)">登录</a>
        </template>
        <a href="javascript:;" @click="jumpPage('/apply')">游客登录</a>
      </div>
    </div>
    <div class="otherType" v-if="activeName != 2">
      <div class="otherTitle">
        <span>其他登录方式</span>
      </div>
      <div class="otherContent">
        <a href="javascript:;">
          <img src="../../../assets/images/wechat.png" alt="" />
          <span>微信</span>
        </a>
        <a href="javascript:;">
          <img src="../../../assets/images/zhifub.png" alt="" />
          <span>支付宝</span>
        </a>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onUnmounted, reactive, ref, watch, onMounted } from "vue";
import config from "@/config";
import urlToScan from "@/components/common/urlToScan.vue";

import { Form, Field } from "vee-validate";
import schema from "@/utils/vee-validate-schema";
import Message from "@/components/library/Message";
import {
  userAccountLogin,
  userMobileLogin,
  userMobileLoginMsg,
  captchaApi,
  userAccountRegister
} from "@/api/user";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useIntervalFn } from "@vueuse/core";
const props = defineProps({
  activeName: {
    type: String
  },
  isLogin: {
    type: Boolean,
    default: true
  }
});
// 注册或登录
const isMsgLogin = ref(false);
const emit = defineEmits(["change"]);
const changeStatus = flag => {
  isMsgLogin.value = flag;
  emit("change", isMsgLogin.value);
};
watch(
  () => props.isLogin,
  val => {
    isMsgLogin.value = val ? false : true;
  },
  {
    immediate: true
  }
);
// 表单数据对象
const form = reactive({
  isAgree: true,
  account: "",
  password: "",
  mobile: null,
  code: null,
  key: ""
});
const showCaptchatImg = ref(false);
const captchatImg = ref("");
// vee-validate 校验基本步骤
// 1. 导入 Form Field 组件 将 form 和 input 进行替换，需要加上name用来指定将来的校验规则函数的
// 2. Field 需要进行数据绑定，字段名称最好和后台接口需要的一致
// 3. 定义Field的name属性指定的校验规则函数，Form的validation-schema接受定义好的校验规则是对象
// 4. 自定义组件需要校验必须先支持v-model 然后Field使用as指定为组件名称
const mySchema = {
  // 校验函数规则：返回true就是校验成功，返回一个字符串就是失败，字符串就是错误提示
  account: schema.account,
  password: schema.password,
  mobile: schema.mobile,
  code: schema.code,
  isAgree: schema.isAgree
};

// 监听isMsgLogin重置表单（数据+清除校验结果）
const formCom = ref(null);
watch(isMsgLogin, () => {
  // 重置数据
  form.isAgree = true;
  form.account = null;
  form.password = null;
  form.mobile = null;
  form.code = null;
  // 如果是没有销毁Field组件，之前的校验结果是不会清除  例如：v-show切换的
  // Form组件提供了一个 resetForm 函数清除校验结果
  formCom.value.resetForm();
});

// setup中获取组件实例 proxy
// const { proxy } = getCurrentInstance()
// proxy.$message({ text: '111' })

// 需要在点击登录的时候对整体表单进行校验
const store = useStore();
const router = useRouter();
const route = useRoute();
const getCaptcha = () => {
  captchaApi()
    .then(data => {
      captchatImg.value = data.data.code;
      form.key = data.data.key;
      showCaptchatImg.value = false;
      setTimeout(() => {
        showCaptchatImg.value = true;
      }, 270000);
    })
    .catch(({ message }) => {
      Message({
        type: "error",
        text: message
      });
    });
};
const login = async () => {
  // Form组件提供了一个 validate 函数作为整体表单校验，当是返回的是一个promise
  const valid = await formCom.value.validate();
  if (valid) {
    const { account, password, code, key } = form;
    const res = await userAccountLogin({
      phone: account,
      password: password
      // captcha: code
    });
    if (res.code != 200) {
      getCaptcha();
      Message({
        type: "error",
        text: res.message
      });
    } else {
      const { data } = res;
      // 存储用户信息
      const { account, nikeName, token, identity } = data;
      store.commit("user/setUser", {
        nickname: nikeName,
        token,
        identity: identity
      });
      router.push(route.query.redirectUrl || "/apply");
      // 成功消息提示
      Message({ type: "success", text: "登录成功" });
      // store.dispatch("cart/mergeCart").then(() => {
      //   // 进行跳转
      //   router.push(route.query.redirectUrl || "/");
      //   // 成功消息提示
      //   Message({ type: "success", text: "登录成功" });
      // });
    }
  }
};

// pause 暂停 resume 开始
// useIntervalFn(回调函数,执行间隔,是否立即开启)
const time = ref(0);
const { pause, resume } = useIntervalFn(
  () => {
    time.value--;
    if (time.value <= 0) {
      pause();
    }
  },
  1000,
  false
);
onUnmounted(() => {
  pause();
});
const jumpPage = url => {
  router.push(url);
};
// 1. 发送验证码
// 1.1 绑定发送验证码按钮点击事件
// 1.2 校验手机号，如果成功才去发送短信（定义API），请求成功开启60s的倒计时，不能再次点击，倒计时结束恢复
// 1.3 如果失败，失败的校验样式显示出来
const send = async () => {
  const valid = mySchema.mobile(form.mobile);
  if (valid === true) {
    // 通过
    if (time.value === 0) {
      // 没有倒计时才可以发送
      userMobileLoginMsg(form.mobile).then(res => {
        if (res.code == 200) {
          Message({ type: "success", text: "发送成功" });
          time.value = 60;
          resume();
        } else {
          Message({
            type: "error",
            text: res?.message || "发送失败"
          });
        }
      });
    }
  } else {
    // 失败，使用vee的错误函数显示错误信息 setFieldError(字段,错误信息)
    formCom.value.setFieldError("mobile", valid);
  }
};
// 注册
const register = async () => {
  const valid = await formCom.value.validate();
  if (valid) {
    const { mobile, password, code } = form;
    const postData = {
      captcha: code,
      identity: props.activeName,
      password: password,
      phone: mobile
    };
    const res = await userAccountRegister(postData);
    if (res.code == 200) {
      Message({ type: "success", text: "注册成功，请登录！" });
      isMsgLogin.value = false;
    } else {
      Message({
        type: "error",
        text: res?.message || "注册失败"
      });
    }
  }
};
// 初始化QQ登录按钮 （官方）
// 1. 准备span有id = qqLoginBtn
// 2. QC.Login({btnId:"qqLoginBtn"})
onMounted(() => {
  // QC.Login({ btnId: 'qqLoginBtn' })
  // getCaptcha();
});
const appId = process.env.VUE_APP_QQAPPID;
const qqBack = encodeURIComponent(process.env.VUE_APP_QQBACK);
const qqUrl = `https://graph.qq.com/oauth2.0/authorize?client_id=${appId}&response_type=token&scope=all&redirect_uri=${qqBack}`;
</script>
<style scoped lang="less">
// 账号容器
.account-box {
  .toggle {
    padding: 15px 40px;
    text-align: right;
    a {
      color: @xtxColor;
      i {
        font-size: 14px;
      }
    }
  }
  .form {
    padding: 20px 40px;

    &-item {
      margin-bottom: 28px;
      .input {
        position: relative;
        height: 36px;
        > i {
          width: 34px;
          height: 34px;
          background: #cfcdcd;
          color: #fff;
          position: absolute;
          left: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 18px;
        }
        input {
          padding-left: 44px;
          border: 1px solid #cfcdcd;
          height: 36px;
          line-height: 36px;
          width: 100%;
          &.error {
            border-color: @priceColor;
          }
          &.active,
          &:focus {
            border-color: @xtxColor;
          }
        }
        .code {
          position: absolute;
          right: 1px;
          top: 1px;
          text-align: center;
          line-height: 34px;
          font-size: 14px;
          background: #f5f5f5;
          color: #666;
          width: 90px;
          height: 34px;
          cursor: pointer;
        }
      }
      > .error {
        position: absolute;
        font-size: 12px;
        line-height: 28px;
        color: @priceColor;
        i {
          font-size: 14px;
          margin-right: 2px;
        }
      }
    }
    .agree {
      a {
        color: #069;
      }
    }
    .btn {
      display: block;
      width: 100%;
      height: 40px;
      color: #fff;
      text-align: center;
      line-height: 40px;
      background: @xtxColor;
      &.disabled {
        background: #cfcdcd;
      }
    }
  }
  .action {
    padding: 0px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .url {
      a {
        color: #666;
        margin-left: 10px;
      }
    }
  }
  .code-item {
    display: flex;
    position: relative;
    .error {
      bottom: -28px;
    }
  }
  .imgs {
    position: relative;
    height: 36px;
    cursor: pointer;
    flex: 1;
    img {
      height: 100%;
      width: 100%;
    }
    span {
      width: 106px;
      line-height: 45px;
      display: inline-block;
      background: rgba(0, 0, 0, 0.4);
      position: absolute;
      left: 0;
      color: #fff;
    }
  }
  .otherType {
    .otherTitle {
      display: flex;
      justify-content: center;
      margin: 10px 0;
      position: relative;
      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 100px;
        top: 50%;
        transform: translateY(-50%);
        background: #979797;
        display: inline-block;
        height: 1px;
      }
      &::before {
        left: 40px;
      }
      &::after {
        right: 40px;
      }
    }
    .otherContent {
      display: flex;
      padding-bottom: 30px;
      justify-content: space-around;
      a {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
  // 二维码容器
  .qrcode-box {
    text-align: center;
    p {
      margin-top: 20px;
      a {
        color: @xtxColor;
        font-size: 16px;
      }
    }
  }
}
</style>
