<template>
  <section class="login-section">
    <div class="pageTitle">
      <img src="@/assets/images/nav_title.png" />
    </div>
    <div class="wrapper">
      <nav>
        <a
          @click="activeName = '1'"
          :class="{ active: activeName === '1' }"
          href="javascript:;"
          >用户</a
        >
        <a
          @click="activeName = '2'"
          :class="{ active: activeName === '2' }"
          href="javascript:;"
          >商户</a
        >
        <a
          @click="activeName = '3'"
          :class="{ active: activeName === '3' }"
          href="javascript:;"
          >评估师 | 咨询师</a
        >
      </nav>
      <!-- 帐号登录&扫码登录 -->
      <LoginForm
        :activeName="activeName"
        :isLogin="isLogin"
        @change="changeForm"
        >表单</LoginForm
      >
      <!-- <div v-else class="qrcode-box">
        <img src="@/assets/images/qrcode.jpg" alt="" />
        <p>打开 <a href="javascript:;">微信</a> 扫码注册</p>
      </div> -->
    </div>
  </section>
  <!-- <LoginFooter /> -->
</template>
<script setup>
import LoginHeader from "./components/login-header";
import LoginFooter from "./components/login-footer";
import LoginForm from "./components/login-form";
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
const activeName = ref("1");

// 存储回跳地址
const store = useStore();
const route = useRoute();

// store.commit('user/setRedirectUrl', route.query.redirectUrl || '/')
const isLogin = computed(() => {
  return route.query.type != "register";
});
const registerStatus = ref(false);
const changeForm = (e) => {
  registerStatus.value = e;
}
</script>
<style scoped lang="less">
.login-section {
  background: url(../../assets/images/bg.png) no-repeat center / cover;
  height: 100%;
  position: fixed;
  width: 100%;
  .pageTitle {
    position: absolute;
    left: 45px;
    top: 45px;
  }
  .wrapper {
    width: 380px;
    background: #fff;
    min-height: 400px;
    position: absolute;
    right: 20%;
    top: 50%;
    transform: translateY(-50%);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    nav {
      height: 55px;
      border-bottom: 1px solid #f5f5f5;
      display: flex;
      padding: 0 40px;
      align-items: center;
      justify-content: space-between;
      a {
        // flex: 1;
        line-height: 1;
        display: inline-block;
        font-size: 18px;
        position: relative;
        &:first-child {
          border-right: 1px solid #f5f5f5;
        }
        &.active {
          color: @xtxColor;
          font-weight: bold;
        }
      }
    }
  }
}
// 二维码容器
.qrcode-box {
  text-align: center;
  padding-top: 40px;
  p {
    margin-top: 20px;
    a {
      color: @xtxColor;
      font-size: 16px;
    }
  }
}
</style>
